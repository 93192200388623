<template>
  <div>
    <StickyTabContainer
      :title="title"
      :back="back"
      :action="action"
      :tabs="options"
      :route="$route"
      :loading="loading"
      :submit="submit"
      :message="message"
      :timeout="500"
      @click-tab="handlerSegmentEvents"
    />
    <SpecialistsFavoriteDialog
      v-if="showModal"
      :favorite-id="specialist.specialist_favorite || favoriteId"
      :specialist="specialist"
      @close="closeModal($event)"
    />
  </div>
</template>

<script>
import {
  StickyTabContainer,
  SpecialistProfile,
  SpecialistFavorite,
  UsersAppVisibility
} from '@seliaco/red-panda'
import SpecialistsFavoriteDialog from '@/components/dialogs/SpecialistsFavoriteDialog'

import AboutMe from '../../sections/v1/AboutMe.vue'
import Services from '../../sections/v1/Services.vue'
import Reviews from '../../sections/v1/Reviews.vue'
import Style from '../../sections/v1/Style.vue'
import {
  SpecialistProfileEvent,
  ScheduleButton,
  BookingAndAppointmentDetailEvent
} from '@/types/events'
import { Specialists } from '@seliaco/red-panda/src/services/specialists/specialists'

export default {
  name: 'SpecialistProfile',
  components: {
    StickyTabContainer,
    SpecialistsFavoriteDialog
  },
  data () {
    return {
      options: [],
      back: {
        icon: 'icon-arrow-full-outline-left',
        callback: () => {
          const query = { ...this.$route.query }
          delete query.back
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back,
              query: {
                ...query,
                directory_origin: 'SpecialistProfileBack'
              }
            })
          } else {
            this.$router.push({ name: 'Home' })
          }
        }
      },
      submit: {
        text: this.$translations['specialist-page'].button,
        loading: false,
        icon: 'icon-timer-calendar-off',
        iconLeft: true,
        iconSize: 'xl',
        callback: () => this.goSchedule(),
        disabled: false
      },
      message: null,
      loading: false,
      isFavorite: false,
      showModal: false,
      showLottie: false,
      specialist: null,
      favoriteId: null
    }
  },
  created () {
    this.getSpecialistData()
  },
  methods: {
    async getSpecialistData () {
      this.loading = true
      const specialist = await Specialists.getBySpecialistId(
        this.$route.params.id
      )

      const specialistData = await SpecialistProfile.getGenInfo(
        this.$route.params.id
      )
      const specialistReviewsData = await SpecialistProfile.getReviews(
        this.$route.params.id,
        1,
        5
      )

      /**
       * Check if message button can be show
       */
      const showMessageButton =
        await UsersAppVisibility.specialistProfileChatButton(
          this.$route.params.id
        )
      if (showMessageButton) {
        this.message = {
          loading: false,
          icon: 'icon-message-circle-off',
          iconLeft: true,
          iconSize: 'xl',
          type: 'outline',
          callback: () => this.goToMessage(specialist?.user?.id),
          disabled: false
        }
      }

      // specialists data init
      this.specialist = {
        id: this.$route.params.id,
        favorite: specialistData.specialist_favorite,
        name: specialistData.about_me.name
      }

      const experience = await SpecialistProfile.getExperience(
        this.$route.params.id
      ).then((response) => {
        return { ...response, icon: 'icon-certificate' }
      })
      const population = await SpecialistProfile.getPopulation(
        this.$route.params.id
      )

      const multimedia = await SpecialistProfile.getMultimedia(
        this.$route.params.id
      ).then((multimedia) => {
        if (multimedia.type === 'VIDEO' && !multimedia.thumbnail) {
          multimedia.thumbnail = specialistData?.picture
        }
        return multimedia
      })

      // set tab options
      this.options = [
        {
          anchor: 'about-me',
          name: this.$translations['specialist-page'].about,
          component: AboutMe,
          props: { specialist: specialistData, multimedia }
        },
        {
          component: Style,
          name: this.$translations['specialist-page'].style,
          anchor: 'style',
          props: {
            specialist: specialistData,
            experience,
            population
          }
        },
        {
          component: Services,
          name: this.$translations['specialist-page'].price.tab,
          anchor: 'services',
          props: { specialist: specialistData }
        }
      ]

      // set review tab
      if (specialistReviewsData.data.options.length) {
        let showButton = false
        if (specialistReviewsData.headers.count > 5) {
          showButton = true
        }

        const reviewsSection = {
          component: Reviews,
          name: this.$translations['specialist-page'].reviews.title,
          anchor: 'reviews',
          props: {
            showButton,
            reviews: specialistReviewsData.data
          }
        }

        this.options = [...this.options, reviewsSection]
      }

      // handler heart
      if (specialistData.specialist_favorite) {
        this.isFavorite = true
      }

      SpecialistProfileEvent.specialistView({
        user: this.$store.getters['auth/user'],
        origin: this.$route.query.origin,
        back: this.$route.query.back?.split('?')[0],
        specialist: {
          id: this.$route.params.id,
          name: this.specialist.name,
          is_public: specialist.is_public,
          gender: specialist.user.gender,
          country: specialist.user.country,
          speciality: specialist.type.name
        }
      })

      this.loading = false
    },
    goToMessage (userSpecialistId) {
      this.$router.push({
        name: 'Messages',
        query: {
          members: JSON.stringify([
            this.$store.getters['auth/user'].id,
            userSpecialistId
          ]),
          back: this.$route.fullPath
        }
      })
    },
    closeModal (event) {
      if (event) {
        this.isFavorite = false
      }
      this.showModal = false
    },
    addSpecialistFavorite () {
      this.showLottie = true
      SpecialistFavorite.create({ specialist: this.$route.params.id })
        .then((res) => {
          this.$toast({
            text: this.$translations['my-specialists']['toast-create'],
            severity: 'success'
          })
          this.isFavorite = true
          this.favoriteId = res.id
          SpecialistProfileEvent.addFavorite({
            specialistId: this.specialist.id,
            specialistName: this.specialist.name
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() =>
          setTimeout(() => {
            this.showLottie = false
          }, 2000)
        )
    },
    goSchedule () {
      const body = {
        user: this.$store.getters['auth/user'],
        specialist_id: this.specialist.id,
        specialist_name: this.specialist.name,
        is_public: this.specialist.is_public,
        origin:
          this.$route.query.origin === 'specialist-card'
            ? `Specialist card to ${this.$route.name}`
            : this.$route.name
      }
      ScheduleButton.onClick(body)

      this.$router
        .push({
          name: 'Schedule',
          query: {
            specialistId: this.$route.params.id,
            back: this.$route.fullPath,
            origin:
              this.$route.query.origin === 'specialist-card'
                ? this.$route.query.origin
                : null
          }
        })
        .then(() =>
          BookingAndAppointmentDetailEvent.loadViewspecialist({
            origin: this.$route.query.back,
            user: this.$store.getters['auth/user']
          })
        )
    },
    handlerSegmentEvents (anchor) {
      SpecialistProfileEvent.selectTab({ anchor })
    }
  },
  computed: {
    action () {
      return {
        callback: () => {
          if (this.isFavorite) {
            this.showModal = true
          } else {
            this.addSpecialistFavorite()
          }
        },
        icon: this.isFavorite ? 'icon-heart-on text-red' : 'icon-heart-off',
        lottie:
          'https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/lottie/lottie-sparkles.json',
        showLottie: this.showLottie
      }
    },
    title () {
      return this.specialist?.name || ''
    }
  }
}
</script>
