<template>
  <div v-if="generalInfo">
    <SpecialistHeader :specialistData="generalInfo" />
    <Multimedia
      class="multimedia"
      :specialistAvatar="generalInfo?.picture"
      :multimedia="multimedia"
    />
    <div class="page-segment-short about" v-if="generalInfo.description">
      <div class="page-segment-short-content">
        <!-- Header -->
        <div class="about-header margin-bottom">
          <div class="icon-about-me icon-display" />
          <div
            class="about-header-text heading-small"
            v-text="generalInfo.title"
          />
        </div>
        <SectionClamp :text="generalInfo.description" text-color="gray-80" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionClamp from '@/components/sections/SectionClamp'
import SpecialistHeader from '../../components/SpecialistHeader'
import Multimedia from '../../components/Multimedia'

export default {
  name: 'AboutMe',
  props: {
    specialist: Object,
    multimedia: Object
  },
  components: {
    SectionClamp,
    SpecialistHeader,
    Multimedia
  },
  data () {
    return {
      generalInfo: this.specialist.about_me
    }
  }
}
</script>

<style lang="sass" scoped>
.multimedia
  margin: 24px 0 16px
.about
  padding: 16px
  &-header
    display: flex
    align-items: center
    gap: 8px
    min-height: 40px
    overflow: hidden
    .icon-display
      color: var(--purple)
    &-text
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  &-link
    width: fit-content
    margin-left: auto
</style>
