<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <Heading
        class="margin-bottom-m-2"
        :text="title"
        title-icon="icon-appointment-off text-purple"
      />

      <template v-if="specialist.is_public">
        <div
          class="content-title text-gray-50 margin-bottom-s"
          v-text="$translations['specialist-page']['schedule-express'].service"
        />

        <div :class="{ 'margin-bottom': benefitsData?.chipText === '' }">
          <DropdownField
            v-model="form.service"
            :options="options"
            :placeholder="
              $translations['specialist-page']['schedule-express'].service
            "
            :label="
              $translations['specialist-page']['schedule-express'].service
            "
          />
        </div>

        <div class="schedule-express-benefit" v-if="benefitsData.chipColor">
          <Chip
            size="small"
            :color="benefitsData.chipColor"
            :text="benefitsData.chipText"
          />

          <!-- promo type -->
          <UsagesCoins
            v-if="benefitsData.packageUsesLeft > 0"
            :option="benefitsData"
            :icon-name="benefitsData.iconName"
            :max="benefitsData.packageMaxUses"
            :remaining="benefitsData.packageUsesLeft"
          />

          <Chip
            class="schedule-express-benefit-price"
            size="small"
            color="gray"
            :text="benefitsData.price"
            v-if="showServiceTotal"
          />
        </div>

        <RadioGroup
          class="margin-bottom-lg"
          :options="modalityOptions"
          v-model="form.virtual"
          :horizontal="true"
          type="semi"
        />

        <div class="schedule-express-date-header">
          <div
            class="content-title text-gray-50"
            v-text="$translations['specialist-page']['schedule-express'].date"
          />
          <TimeZoneSelector />
        </div>

        <AppointmentDateSelector
          :specialist-id="specialist.id"
          :service-id="getId"
          :service-modality="form.virtual"
          :num-slots="3"
          :loading-external="loading"
          :has-info-extra="false"
          @changed="setDate"
          v-if="specialist.id && form.service && form.virtual"
        />

        <Button
          class="margin-top"
          :text="scheduleButtonText"
          @clicked="handlerSchedule"
          :disable="disable"
          icon="icon-timer-calendar-off"
          :icon-left="true"
        />

        <div class="segment padding-all-zero">
          <div class="segment-content schedule-express-additional-hours">
            <div
              class="body-small"
              v-text="
                $translations['appointment-pages']['add-hours-date'][
                  'no-availability-alt'
                ]
              "
            />
            <div
              class="content-small text-purple"
              @click="goToTypeform"
              v-text="
                $translations['appointment-pages']['add-hours-date'][
                  'no-availability-button'
                ]
              "
            ></div>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="body text-gray-50"
          v-text="$translations['appointment-pages']['no-public'].title"
        />
        <Button
          type="link"
          :text="$translations['appointment-pages']['no-public'].button"
          @clicked="goToMatching"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { DropdownField } from '@seliaco/sea-otter'
import UsagesCoins from '@/components/UsagesCoins'

import {
  Button,
  Heading,
  RadioGroup,
  Settings,
  Chip,
  Appointments,
  RoleMixin,
} from '@seliaco/red-panda'

import AppointmentDateSelector from '@/components/fields/v2/AppointmentDateSelector'
import { AppointmentFlowEvent, ScheduleButton } from '@/types/events'
import TimeZoneSelector from '@/components/fields/TimeZoneSelector.vue'

import { Browser } from '@capacitor/browser'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleExpress',
  components: {
    DropdownField,
    Button,
    Heading,
    RadioGroup,
    AppointmentDateSelector,
    Chip,
    UsagesCoins,
    TimeZoneSelector
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      form: {
        service: null,
        time: '',
        virtual: 'VIRTUAL'
      },
      loading: false
    }
  },
  created () {
    if (this.specialist.specialist_type_code === 'PSYCHOLOGY') {
      const service = this.specialist.virtual_services.find(
        (service) => service.code === 'terapia_individual'
      )

      this.form.service =
        service?.code || this.specialist.virtual_services[0].code
    } else {
      this.form.service = this.specialist.virtual_services[0].code
    }
  },
  methods: {
    async handlerSchedule () {
      const service = this.specialist.virtual_services.find(
        (service) => service.code === this.form.service
      )

      const body = {
        virtual: this.form.virtual === 'VIRTUAL',
        specialist: this.specialist.id,
        specialist_service: service.specialist_service_type_id,
        starts_at: this.form.time.code,
        patient: this.user.id
      }

      const segmentBody = {
        user: this.$store.getters['auth/user'],
        specialist_id: this.specialist.id,
        specialist_name: this.specialist.name,
        is_public: this.specialist.is_public,
        origin: 'Specialist Profile Desktop'
      }

      ScheduleButton.onClick(segmentBody)

      const appointment = await Appointments.reserveAppointment(body)

      this.$router.push({
        name: 'Schedule',
        query: {
          specialistId: this.specialist.id,
          appointmentId: appointment.id,
          noReschedule: true,
          back: this.$route.fullPath,
          origin: 'schedule-express'
        }
      })
    },
    setDate (date) {
      this.form.time = date
    },
    goToMatching () {
      this.$router.push({ name: 'QuestionnaireContainer' })
    },
    goToTypeform () {
      Settings.get('SELIA_USERS_AVAILABILITY_FORM_URL')
        .then((value) => {
          const url = value.parse_value
            .replaceAll('{USER_ID}', this.user.id)
            .replaceAll('{SPECIALIST_ID}', this.specialist.id)

          const service = this.service.find(
            (service) => this.form.service === service.slug
          )
          const body = {
            specialist_id: this.specialist.id,
            specialist_name: this.specialist.name,
            service_name: service.title,
            service_price: service.price,
            user: this.user,
            phone_number: this.user.phone,
            email: this.user.email
          }

          AppointmentFlowEvent.Appointment_Form_Click(body)
          Browser.open({ url }).catch((e) => {
            this.$toast({
              text: e.message || this.$translations.error.default,
              severity: 'error'
            })
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message,
            severity: 'error'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      service: 'specialistProfileService/service',
      role: 'auth/role'
    }),
    options () {
      return this.specialist.virtual_services.map((service) => {
        const serviceComplete = this.service.find(
          (item) => item.slug === service.code
        )

        const price = serviceComplete?.price ? `• ${serviceComplete?.price} •` : '•'

        return {
          label: `${service.name_raw || service.name} ${price} ${
            service.duration
          } min`,
          value: service.code
        }
      })
    },
    getId () {
      return this.specialist.virtual_services.find(
        (service) => service.code === this.form.service
      )?.id
    },
    modalityOptions () {
      let options = [
        {
          text: this.$translations['specialist-page'].modality.online,
          value: 'VIRTUAL'
        }
      ]

      const hasPerson = this.specialist.virtual_services.find(
        (service) =>
          service.code === this.form.service && service.in_person_enabled
      )

      if (hasPerson) {
        options.push({
          text: this.$translations['specialist-page'].modality['face-to-face'],
          value: 'FACE_TO_FACE'
        })
      }

      return options
    },
    benefitsData () {
      if (this.service.length > 0) {
        const service = this.service.find(
          (service) => this.form.service === service.slug
        )

        return {
          chipColor: service?.chip?.color?.toLowerCase() || '',
          chipText: service?.chip?.text || '',
          packageUsesLeft: service?.package?.uses_left || 0,
          packageMaxUses: service?.package?.max_uses || 0,
          iconName: service?.icon || '',
          price: service?.original_price || ''
        }
      }

      return {
        chipColor: '',
        chipText: '',
        packageUsesLeft: 0,
        packageMaxUses: 0,
        iconName: '',
        price: ''
      }
    },
    title () {
      return this.$translations['specialist-page'][
        'schedule-express'
      ].title.replace('{specialist}', this.specialist.name)
    },
    disable () {
      return !this.form.service || !this.form.time
    },
    scheduleButtonText () {
      const scheduleText =
        this.$translations['specialist-page']['schedule-express'].button

      if (this.form.time?.code) {
        const dateSelected = this.$moment(this.form.time.code)

        let day = 'ddd'
        const momentFormat = `${day}, D MMM, hh:mm a`

        const bannerText = `${dateSelected
          .tz(this.$store.getters.userTimezone)
          .format(momentFormat)}`

        const scheduleText =
          this.$translations['specialist-page']['schedule-express'].button

        return `${scheduleText}: ${bannerText}`
      }

      return scheduleText
    },
    showServiceTotal () {
      return this.allowedByRole([this.roleType.USER, this.roleType.USER_B2B]) && !this.benefitsData.packageMaxUses
    }
  },
  mixins: [
    RoleMixin
  ],
  watch: {
    'form.service': {
      handler () {
        this.loading = true

        setTimeout(() => {
          this.loading = false
        })
      }
    },
    'form.virtual': {
      handler () {
        this.loading = true

        setTimeout(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.schedule-express-benefit
  display: flex
  gap: 4px
  margin-bottom: 22px
  margin-top: 8px

.schedule-express-benefit-price
  ::v-deep .chip-text
      text-decoration: line-through

.schedule-express-date-header
  display: flex
  justify-content: space-between

.schedule-express-additional-hours
  display: flex
  justify-content: center
  align-items: center
  gap: 4px
</style>
