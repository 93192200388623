<template>
  <div v-if="multimedia && multimedia.url && (multimedia.type === 'AUDIO')">
    <!-- multimedia -->
    <div class="segment padding-y-zero" style="margin-bottom: 8px">
      <div class="segment-content">
        <AudioCard :player="{
          title: multimedia.title,
          fileUrl: multimedia.url
        }" v-if="multimedia.type === 'AUDIO'" @click.once="segmentAudioEvent" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  AudioCard
  // YouTubePlayer,
  // VideoPostCard
} from '@seliaco/red-panda'
import { SpecialistProfileEvent } from '@/types/events'

export default {
  name: 'Multimedia',
  components: {
    AudioCard
    // VideoPostCard,
    // YouTubePlayer
  },
  props: {
    specialistAvatar: String,
    multimedia: Object
  },
  methods: {
    segmentAudioEvent () {
      this.$segment.track(SpecialistProfileEvent.select_playspecialistaudio)
    },
    segmentVideoEvent () {
      this.$segment.track(SpecialistProfileEvent.select_playspecialistvideo)
    }
  }
}
</script>
