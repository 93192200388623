<template>
  <div class="page-segment-short">
    <div class="page-segment-short-content">
      <div class="title">
        <div class="title-text heading-small" v-text="reviews.title" />
      </div>
      <div
        class="container padding-y"
        v-for="(review, index) in reviews.options"
        :key="index"
      >
        <!-- date -->
        <div class="date margin-bottom-sm">
          <div class="icon icon-recommend-on" />
          <div class="text content-title" v-text="review.date" />
        </div>
        <!-- content -->
        <div class="body" v-text="review.content" />
      </div>

      <div class="margin-y">
        <Button
          v-if="showButton"
          :text="$translations['specialist-page'].reviews['see-all']"
          type="outline"
          @clicked="goToReview"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'
export default {
  name: 'Reviews',
  components: { Button },
  props: {
    reviews: Object,
    showButton: Boolean
  },
  methods: {
    goToReview () {
      this.$router.push({
        name: 'SpecialistReviews',
        params: { id: this.$route.params.id }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  border-bottom: 1px solid var(--gray-10)

.title
  display: flex
  align-items: center
  min-height: 40px
  overflow: hidden
  &-text
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.skeleton-container
  display: flex
  gap: 8px

.date
  display: flex
  gap: 8px
  .icon
    flex: none
    color: var(--purple)
    font-size: var(--xl)
    align-self: center
  .text
    text-transform: capitalize
    flex-grow: 1
    align-self: center
    color: var(--gray-80)
</style>
