var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.specialist)?_c('div',{staticClass:"style"},[(_vm.first_session && _vm.first_session.description)?[_c('div',{staticClass:"page-segment-short"},[_c('div',{staticClass:"page-segment-short-content"},[_c('Heading',{staticClass:"margin-bottom",attrs:{"text":_vm.first_session.title,"title-icon":`${_vm.first_session.icon} text-purple`}}),_c('SectionClamp',{attrs:{"text":_vm.first_session.description,"maxItems":4,"text-color":"gray-80"}})],1)])]:_vm._e(),(
      _vm.specialist.consultation_style &&
      _vm.specialist.consultation_style_description
    )?[_c('div',{staticClass:"page-segment-short"},[_c('div',{staticClass:"page-segment-short-content"},[_c('Heading',{staticClass:"margin-bottom",attrs:{"text":_vm.content.consultation_style_title,"title-icon":"icon-therapeutic-approach text-purple"}}),(
            _vm.specialist.consultation_style &&
            _vm.specialist.consultation_style.length
          )?_c('div',{staticClass:"chips"},_vm._l((_vm.specialist.consultation_style),function(item,index){return _c('Chip',{key:index,attrs:{"size":"pill-field","color":"white-outline","icon-position":"left","emoji":item.icon,"text":item.name}})}),1):_vm._e(),_c('SectionClamp',{staticClass:"margin-top",attrs:{"text":_vm.specialist.consultation_style_description,"text-color":"gray-80"}})],1)])]:_vm._e(),(_vm.specialities && _vm.specialities.options.length)?_c('div',{staticClass:"page-segment-short"},[_c('div',{staticClass:"page-segment-short-content"},[_c('Heading',{attrs:{"text":_vm.specialities.title,"title-icon":`${_vm.specialities.icon} text-purple`}}),_c('SectionClamp',{attrs:{"items":_vm.specialities.options,"maxItems":4,"text-color":"gray-80"}}),(_vm.population && _vm.population.length)?_c('div',[_c('hr',{staticClass:"margin-y-sm"}),_c('div',{staticClass:"content-title",domProps:{"textContent":_vm._s(_vm.$translations['specialist-page']['work-with'])}}),_c('div',{staticClass:"population-chips"},_vm._l((_vm.population),function(item,index){return _c('Chip',{key:index,attrs:{"size":"small","color":"purple","text":item.name}})}),1)]):_vm._e()],1)]):_vm._e(),(_vm.specialistCertifications && _vm.experiences.length)?_c('div',{staticClass:"page-segment-short"},[_c('div',{staticClass:"page-segment-short-content"},[_c('Heading',{attrs:{"text":_vm.content.experience_title,"title-icon":"icon-certificate text-purple"}}),_c('SectionClamp',{attrs:{"items":_vm.experiences,"extended-item":true,"maxItems":2,"text-color":"gray-80"}})],1)]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }