<template>
  <SpecialistProfile v-if="specialistProfileGb" />
  <SpecialistProfileV3 v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import SpecialistProfile from './page/v2/Specialist.vue'
import SpecialistProfileV3 from './page/v3/Specialist.vue'

export default {
  name: 'SpecialistProfileWrapper',
  components: {
    SpecialistProfile,
    SpecialistProfileV3
  },
  computed: {
    ...mapGetters({
      specialistProfileGb: 'growthBook/getSpecialistProfile'
    })
  }
}
</script>
