<template>
  <div>
    <div class="page-segment-short price">
      <div class="page-segment-short-content">
        <!-- Header -->
        <div class="price-header">
          <div class="icon-prices-offered icon-display"></div>
          <div
            class="price-header-text heading-small"
            v-text="$translations['specialist-page'].price.tab"
          />
        </div>

        <!-- Modalities -->
        <ModalitySelector
          @changed="setModality"
          :presential-option="hasInPersonService"
        />

        <!-- Services -->
        <ServiceCard
          v-for="(service, index) in services"
          :key="index"
          :service="service"
          class="margin-bottom"
          :version="cardVer"
        />
        <Skeleton
          v-if="loading"
          height="200px"
          width="100%"
          border-radius="16px"
        />

        <!-- Packages -->
        <PackagesBanner
          v-if="packagesData && packagesData.description && cardVer < 1"
          :package-data="packagesData"
          :specialist="specialist"
        />

        <!-- Growthbook - Version 2 & 3 - Package button -->
        <Button
          v-if="cardVer > 0"
          :text="$translations['specialist-page'].price.packages"
          @clicked="goToPackageView"
          :type="'outline'"
        />
      </div>
    </div>
    <!-- Programs -->
    <div class="page-segment-short" v-if="programs.length > 0">
      <div class="page-segment-short-content programs">
        <Programs
          :section-padding="false"
          title-icon="icon-display icon-graph-on text-purple"
        />
      </div>
    </div>
    <!-- Address -->
    <div class="page-segment-short" v-if="address && address.address">
      <div class="page-segment-short-content address">
        <div class="header address-header margin-bottom">
          <div
            class="heading-small header-text header-title"
            v-text="address.title"
          />
          <OpenInGoogleMaps
            v-if="(address && address.place_id) || address.address"
            class="header-link"
            :language="$store.getters.language.lang"
            :place_id="address.place_id"
            :full-address="address.address"
          />
        </div>

        <div class="address-container">
          <span
            class="icon-display text-gray-60 icon-consultory-directory margin-right-sm"
          ></span>
          <span class="body-small" v-text="address.address" />
        </div>
      </div>
    </div>
    <!-- Languages -->
    <div class="page-segment-short" v-if="languages?.options?.length">
      <div class="page-segment-short-content languages">
        <div class="header">
          <div
            class="heading-small header-text header-title"
            v-text="languages.title"
          />
        </div>

        <div class="languages-container margin-top">
          <div
            class="languages-container-item"
            v-for="(item, index) in languages.options"
            :key="index"
          >
            <img
              :src="flag(item)"
              class="languages-container-item-image"
              alt=""
            />
            <div
              class="body languages-container-item-name"
              v-text="$translations.languages[item]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { userStatus } from '@/types/state'
import ServiceCard from '@/views/specialist/components/ServiceCard'
import PackagesBanner from '@/views/specialist/components/PackagesBanner'
import ModalitySelector from '@/components/fields/ModalitySelector'
import {
  SpecialistProfile,
  OpenInGoogleMaps,
  FLAGS,
  Skeleton,
  Button,
  RoleMixin
} from '@seliaco/red-panda'
import Programs from '@/views/home/components/Programs'

export default {
  name: 'Services',
  components: {
    Programs,
    ServiceCard,
    ModalitySelector,
    PackagesBanner,
    Skeleton,
    OpenInGoogleMaps,
    Button
  },
  mixins: [RoleMixin],
  props: {
    specialist: Object
  },
  data () {
    return {
      servicesData: null,
      form: {
        virtual: true,
        service: null
      },
      packagesData: null,
      loading: true,
      address: null,
      languages: null,
      cardVer: 0,
      hasInPersonService: false
    }
  },
  created () {
    this.$store.dispatch(
      'programs/getSpecialistPrograms',
      this.$route.params.id
    )
    this.setSectionData()
    this.getPackagesVersion()
  },
  methods: {
    goToPackageView () {
      this.$router.push({
        name: 'Packages',
        query: {
          specialists: JSON.stringify([
            {
              id: this.$route.params.id,
              name: this.specialist.about_me.name,
              picture: this.specialist.about_me.picture
            }
          ]),
          back: this.$route.fullPath
        }
      })
    },
    getPackagesVersion () {
      if (this.user.status !== userStatus.active && !this.packages) {
        this.cardVer = this.serviceCardVer
      }
    },
    setModality (value) {
      this.form.virtual = value.code === 'ONLINE'
      this.typeOfModality(value.code)
      this.valid()
    },
    typeOfModality (value) {
      this.showAddress = value === 'FACE_TO_FACE'
    },
    valid () {
      const valid = Boolean(this.form.service)

      if (this.form.service) {
        if (this.isEditing) {
          this.$emit('valid', !valid)
          this.$emit('action', this.form)
        } else {
          this.$emit('action', this.form)
        }
      }
    },
    getServices () {
      this.loading = true
      SpecialistProfile.getServices(this.$route.params.id)
        .then((services) => {
          this.servicesData = services.options
          const inpersonService = this.servicesData?.filter(
            (item) => item.kalendme_in_person_link_id
          )
          this.hasInPersonService = Boolean(inpersonService.length > 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPackageData () {
      if (this.roleType.USER_HIDE_PRICES === this.role) {
        return
      }

      SpecialistProfile.getPackageBanner(this.$route.params.id).then((data) => {
        this.packagesData = data
      })
    },
    getAddress () {
      if (
        this.specialist.modality.options.find(
          (modality) => modality === 'IN_PERSON'
        )
      ) {
        SpecialistProfile.getAddress(this.$route.params.id).then((data) => {
          this.address = data
        })
      }
    },
    setSectionData () {
      this.getServices()
      this.getPackageData()
      this.getAddress()
      this.languages = this.specialist.language
    },
    flag (lang) {
      const languageCodes = this.$languageCodes.find(
        (option) => option.name === lang
      )

      let flag = languageCodes.flag

      if (flag === 'pt') {
        flag = 'br'
      }

      return FLAGS[`${flag}Flag`]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      role: 'auth/role',
      packages: 'myPackages/getPackages',
      serviceCardVer: 'growthBook/getServiceCardVer',
      programs: 'programs/getPrograms'
    }),
    services () {
      if (!this.form.virtual) {
        return this.servicesData?.filter(
          (item) => item.kalendme_in_person_link_id
        )
      }
      return this.servicesData
    }
  }
}
</script>

<style lang="sass" scoped>
.price
  padding: 16px
  &-header
    display: flex
    align-items: center
    gap: 8px
    min-height: 40px
    &-text
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    .icon-display
      color: var(--purple)
.address
  &-container
    display: flex
    align-items: center
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 6px
    gap: 0 !important
.programs
  &-container
    display: flex
    align-items: center
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 6px
    gap: 0 !important
.languages
  &-container
    display: flex
    gap: 16px
    flex-wrap: wrap
    &-item
      display: flex
      align-items: center
      gap: 4px
      &-image
        width: 20px
        height: 20px
        border-radius: 35%
.address,
.languages
  .header
    white-space: nowrap
    display: flex
    align-items: center
    gap: 8px
    min-height: 40px
    overflow: hidden
    &-title
      width: 100%
</style>
