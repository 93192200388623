<template>
  <div>
    <SpecialistHeader
      :specialist="specialist"
      :is-dekstop="isDesktop"
      :content="content"
      v-if="!isDesktop"
    />
    <div
      class="segment padding-all-zero"
      v-if="!isDesktop && !specialist.is_public"
    >
      <div class="segment-content">
        <InfoBanner
          :text="$translations['appointment-pages']['no-public'].mobile"
          icon="icon-information-off"
          icon-color="yellow"
          :has-borderbottom="true"
          text-color="gray-60"
        />
      </div>
    </div>
    <Multimedia
      class="multimedia"
      :specialistAvatar="specialist.picture"
      :multimedia="multimedia"
    />
    <div class="page-segment-short about" v-if="specialist.description">
      <div class="page-segment-short-content">
        <Heading
          class="margin-bottom"
          :text="content.about_me_title"
          title-icon="icon-about-me text-purple"
        />
        <SectionClamp :text="specialist.description" text-color="gray-80" />
      </div>
    </div>
  </div>
</template>

<script>
import { Heading, InfoBanner } from '@seliaco/red-panda'
import SectionClamp from '@/components/sections/SectionClamp'
import SpecialistHeader from '../../components/v2/SpecialistHeader'
import Multimedia from '../../components/Multimedia'

export default {
  name: 'AboutMe',
  props: {
    specialist: Object,
    multimedia: Object,
    isDesktop: Boolean,
    content: Object
  },
  components: {
    SectionClamp,
    SpecialistHeader,
    Multimedia,
    Heading,
    InfoBanner
  },
  created () {
    if (this.isDesktop) {
      return []
    }
  },
  computed: {
    multimediaData () {
      return {
        ...this.specialist.multimedia
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.multimedia
  margin: 24px 0 16px
.about
  padding: 16px
</style>
