<template>
  <div class="style" v-if="specialist">
    <!-- First Session -->
    <template v-if="first_session && first_session.description">
      <div class="page-segment-short">
        <div class="page-segment-short-content">
          <Heading
            class="margin-bottom"
            :text="first_session.title"
            :title-icon="`${first_session.icon} text-purple`"
          />

          <SectionClamp
            :text="first_session.description"
            :maxItems="4"
            text-color="gray-80"
          />
        </div>
      </div>
    </template>
    <!-- Consultation Style -->
    <template
      v-if="
        specialist.consultation_style &&
        specialist.consultation_style_description
      "
    >
      <div class="page-segment-short">
        <div class="page-segment-short-content">
          <Heading
            class="margin-bottom"
            :text="content.consultation_style_title"
            title-icon="icon-therapeutic-approach text-purple"
          />

          <div
            class="chips"
            v-if="
              specialist.consultation_style &&
              specialist.consultation_style.length
            "
          >
            <Chip
              v-for="(item, index) in specialist.consultation_style"
              :key="index"
              size="pill-field"
              color="white-outline"
              icon-position="left"
              :emoji="item.icon"
              :text="item.name"
            />
          </div>

          <SectionClamp
            class="margin-top"
            :text="specialist.consultation_style_description"
            text-color="gray-80"
          />
        </div>
      </div>
    </template>
    <!-- Specialities -->
    <div
      class="page-segment-short"
      v-if="specialities && specialities.options.length"
    >
      <div class="page-segment-short-content">
        <Heading
          :text="specialities.title"
          :title-icon="`${specialities.icon} text-purple`"
        />
        <SectionClamp
          :items="specialities.options"
          :maxItems="4"
          text-color="gray-80"
        />

        <!-- Population -->
        <div v-if="population && population.length">
          <hr class="margin-y-sm" />
          <div
            class="content-title"
            v-text="$translations['specialist-page']['work-with']"
          />
          <div class="population-chips">
            <Chip
              v-for="(item, index) in population"
              :key="index"
              size="small"
              color="purple"
              :text="item.name"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Experience -->
    <div
      class="page-segment-short"
      v-if="specialistCertifications && experiences.length"
    >
      <div class="page-segment-short-content">
        <Heading
          :text="content.experience_title"
          title-icon="icon-certificate text-purple"
        />
        <SectionClamp
          :items="experiences"
          :extended-item="true"
          :maxItems="2"
          text-color="gray-80"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionClamp from '@/components/sections/SectionClamp.vue'
import { Chip, Heading } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'Style',
  components: {
    SectionClamp,
    Chip,
    Heading
  },
  props: {
    content: Object,
    specialist: Object,
    experiences: Array,
    population: Array
  },
  data () {
    return {
      specialities: null,
      consultation_style: null,
      first_session: null
    }
  },
  created () {
    this.setProfileData()
  },
  methods: {
    setProfileData () {
      this.specialities = {
        title: this.content.specialities_title,
        options: this.specialist?.specialities.map((item) => ({
          key: item.code,
          description: item.description,
          name: item.name
        })),
        icon: 'icon-specialities'
      }
      this.consultation_style = {
        description:
          this.specialist?.consultation_style?.consultation_style_description,
        title: this.content.consultation_style_title,
        options: this.specialist?.consultation_style,
        icon: 'icon-therapeutic-approach'
      }
      this.first_session = {
        title: this.content.first_session_title,
        description: this.specialist?.first_session_description,
        icon: 'icon-activity'
      }
    }
  },
  computed: {
    ...mapGetters({
      specialistCertifications: 'growthBook/getSpecialistCertifications'
    })
  }
}
</script>

<style lang="sass" scoped>
.chips
  display: flex
  gap: 4px
  flex-wrap: wrap
  align-items: flex-end
.chip
  border-radius: var(--border-3)
  ::v-deep .body-small
    color: var(--gray-80) !important
    font-weight: 600
  ::v-deep .icon-purple
    color: var(--purple) !important
.icon-display
  color: var(--purple)
.population-chips
  margin-top: 10px
  display: flex
  gap: 4px
  flex-wrap: wrap
  ::v-deep .body-small
    overflow: unset
    text-overflow: unset

::v-deep .item
  gap: 0px!important
</style>
