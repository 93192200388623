<template>
  <div class="style" v-if="specialist">
    <!-- First Session -->
    <template v-if="first_session && first_session.description">
      <div class="page-segment-short">
        <div class="page-segment-short-content">
          <div class="header margin-bottom">
            <div :class="first_session.icon" class="icon-display" />
            <div
              class="header-text heading-small"
              v-text="first_session.title"
            />
          </div>
          <SectionClamp
            :text="first_session.description"
            :maxItems="4"
            text-color="gray-80"
          />
        </div>
      </div>
    </template>
    <!-- Consultation Style -->
    <template v-if="consultation_style && consultation_style.description">
      <div class="page-segment-short">
        <div class="page-segment-short-content">
          <div class="header margin-bottom">
            <div :class="consultation_style.icon" class="icon-display" />
            <div
              class="header-text heading-small"
              v-text="consultation_style.title"
            />
          </div>
          <div
            class="chips"
            v-if="
              consultation_style.options && consultation_style.options.length
            "
          >
            <Chip
              v-for="(item, index) in consultation_style.options"
              :key="index"
              size="pill-field"
              color="white-outline"
              icon-position="left"
              :emoji="item.icon_code"
              :text="item.name"
            />
          </div>

          <SectionClamp
            class="margin-top"
            :text="consultation_style.description"
            text-color="gray-80"
          />
        </div>
      </div>
    </template>
    <!-- Specialities -->
    <div
      class="page-segment-short"
      v-if="specialities && specialities.options.length"
    >
      <div class="page-segment-short-content">
        <div class="header">
          <div :class="specialities.icon" class="icon-display" />
          <div class="header-text heading-small" v-text="specialities.title" />
        </div>
        <SectionClamp
          :items="specialities.options"
          :maxItems="4"
          text-color="gray-80"
        />

        <!-- Population -->
        <div v-if="population && population.options.length">
          <hr class="margin-y-sm" />
          <div class="content-title" v-text="population.title" />
          <div class="population-chips">
            <Chip
              v-for="(item, index) in population.options"
              :key="index"
              size="small"
              color="purple"
              :text="item.name"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Experience -->
    <div
      class="page-segment-short"
      v-if="specialistCertifications && experience && experience.options.length"
    >
      <div class="page-segment-short-content">
        <div class="header">
          <div :class="experience.icon" class="icon-display" />
          <div class="header-text heading-small" v-text="experience.title" />
        </div>
        <SectionClamp
          :items="experience.options"
          :extended-item="true"
          :maxItems="2"
          text-color="gray-80"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionClamp from '@/components/sections/SectionClamp.vue'
import { Chip } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'Style',
  components: {
    SectionClamp,
    Chip
  },
  props: {
    specialist: Object,
    experience: Object,
    population: Object
  },
  data () {
    return {
      specialities: null,
      consultation_style: null,
      first_session: null
    }
  },
  created () {
    this.setProfileData()
  },
  methods: {
    setProfileData () {
      this.specialities = {
        ...this.specialist.specialities,
        icon: 'icon-specialities'
      }
      this.consultation_style = {
        description: this.specialist?.consultation_style?.description,
        title: this.specialist?.consultation_style?.title,
        options: this.specialist?.consultation_style?.options,
        icon: 'icon-therapeutic-approach'
      }
      this.first_session = {
        title: this.specialist?.consultation_style?.first_session_title,
        description:
          this.specialist?.consultation_style?.first_session_description,
        icon: 'icon-activity'
      }
    }
  },
  computed: {
    ...mapGetters({
      specialistCertifications: 'growthBook/getSpecialistCertifications'
    })
  }
}
</script>

<style lang="sass" scoped>
.chips
  display: flex
  gap: 4px
  flex-wrap: wrap
  align-items: flex-end
.chip
  border-radius: var(--border-3)
  ::v-deep .body-small
    color: var(--gray-80) !important
    font-weight: 600
  ::v-deep .icon-purple
    color: var(--purple) !important
.icon-display
  color: var(--purple)
.style
  .header
    display: flex
    align-items: center
    gap: 8px
    min-height: 40px
    overflow: hidden
    &-text
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    &-title
      width: 100%
    &-link
      padding: 0
      text-align: right
      width: 120px
      white-space: nowrap
.population-chips
  margin-top: 10px
  display: flex
  gap: 4px
  flex-wrap: wrap
  ::v-deep .body-small
    overflow: unset
    text-overflow: unset

::v-deep .item
  gap: 0px!important
</style>
