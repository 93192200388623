<template>
  <!-- header: avatar, name and speciality -->
  <div class="header-container page-segment-short padding-all-zero">
    <div class="page-segment-short-content" v-if="specialistData">
      <div class="header padding-all">
        <!-- avatar -->
        <div class="specialist-img">
          <div class="specialist-img-modal" v-if="showModalPic">
            <div
              class="specialist-img-modal-backdrop cursor-pointer"
              @click="closeModalPic"
            />
            <img
              class="specialist-img-modal-pic modal-in"
              :src="avatar"
              :alt="specialistData.name"
              ref="modal"
            />
          </div>
          <div class="specialist-img-avatar">
            <div class="img-content" v-if="multimedia.type === 'VIDEO'" />
            <img
              class="video-icon"
              :src="videoIcon"
              alt=""
              v-if="multimedia.type === 'VIDEO'"
            />
            <img
              class="img cursor-pointer"
              :src="avatar"
              :alt="specialistData.name"
              @click="openMultimedia"
            />
            <img
              v-if="countryFlag"
              class="specialist-img-flag"
              :src="countryFlag"
            />
          </div>

          <YouTubePlayer
            v-if="showMultimediaPlayer"
            :title="multimedia.title"
            :url="multimedia.url"
            @close="showMultimediaPlayer = false"
          />
        </div>

        <div class="specialist-info">
          <!-- name -->
          <div class="heading" v-text="specialistData.name" />

          <div :class="{ 'specialist-info-with-badge': hasBadge }">
            <!-- speciality -->
            <div
              class="body"
              v-if="specialistData.type"
              v-text="specialistData.type"
            />

            <div class="body" v-if="hasBadge" v-text="'•'" />

            <!-- country -->
            <div
              class="body"
              v-if="specialistData.country"
              v-text="specialistData.country"
            />
          </div>

          <!-- badges -->
          <div class="badges" v-if="hasBadge">
            <div
              class="badges-retention margin-bottom-xs"
              v-if="specialistData.retention_badge"
            >
              <img class="badges-retention-img" :src="img" alt="badge" />
              <div
                class="body text-purple-110"
                v-text="specialistData.retention_badge.text"
              />
              <div
                class="icon-question-mark-off icon-display text-gray-40 margin-left-xl"
                v-tooltip="specialistData.retention_badge.tooltip"
              />
            </div>
            <div
              v-if="specialistData.selia_experience_badge"
              class="badges-experience text-purple-110 body"
              v-html="specialistData.selia_experience_badge"
            />
          </div>
        </div>
      </div>

      <!-- Metrics -->
      <div class="metrics padding-x margin-bottom">
        <!-- experience -->
        <div class="body padding-x" v-html="specialistData.experience" />
        <!-- satisfaction -->
        <div
          class="body metrics-satisfaction"
          v-html="specialistData.satisfaction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FLAGS,
  SpecialistProfile,
  YouTubePlayer,
  tooltip
} from '@seliaco/red-panda'
import { SpecialistProfileEvent } from '@/types/events'
import { videoIcon } from '@seliaco/red-panda/src/assets/svg'
export default {
  name: 'SpecialistHeader',
  props: { specialistData: Object },
  directives: { tooltip },
  components: { YouTubePlayer },
  data () {
    return {
      img: require('@/assets/badge.png'),
      showModalPic: false,
      multimedia: {
        duration: null,
        url: null,
        thumbnail: null,
        title: null,
        type: null
      },
      showMultimediaPlayer: false,
      hasBadge:
        this.specialistData.retention_badge ||
        this.specialistData.selia_experience_badge,
      videoIcon: videoIcon
    }
  },
  created () {
    this.getMedia()
  },
  methods: {
    getMedia () {
      // To change after updating endpoint that returns the specialist data
      SpecialistProfile.getMultimedia(this.$route.params.id).then(
        (multimedia) => {
          if (multimedia && multimedia.type === 'VIDEO') {
            this.multimedia = multimedia
          }
        }
      )
    },
    openMultimedia () {
      if (this.multimedia.type === 'VIDEO') {
        this.showMultimediaPlayer = true
        this.$segment.track(SpecialistProfileEvent.select_playspecialistvideo)
      } else {
        this.showModalPic = true
      }
    },
    closeModalPic () {
      this.$refs.modal.classList.remove('modal-in')
      this.$refs.modal.classList.add('modal-out')
      setTimeout(() => {
        this.showModalPic = false
      }, 140)
    }
  },
  computed: {
    avatar () {
      return (
        this.specialistData?.picture || require('@/assets/images/avatar.png')
      )
    },
    countryFlag () {
      if (!this.specialistData.flag) {
        return null
      }
      return FLAGS[`${this.specialistData.flag.toLowerCase()}Flag`]
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  padding-bottom: 0
  .img
    height: 96px
    width: 96px
    object-position: center
    object-fit: cover
    border-radius: 9999px
    border: 2px solid var(--white)
    z-index: 9
    position: relative
    max-width: inherit
    &-content
      background: linear-gradient(130deg, var(--purple-20) 24%, var(--purple) 60%, var(--purple-60) 20%)
      animation: gradient 2s ease infinite
      background-size: 400% 400%
      width: 100px
      height: 100px
      position: absolute
      display: block
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      border-radius: 50%

  .video-icon
    flex: none
    position: absolute
    left: 38px
    bottom: -2px
    object-fit: cover
    height: 20px
    width: 32px
    border-radius: 4px
    z-index: 12

  .specialist-info
    display: grid
    gap: 4px
    white-space: nowrap
    margin-left: 20px
    align-self: center
    &-with-badge
      display: flex
      gap: 8px
    .heading
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
    .badges
      &-retention
        display: flex
        align-items: center
        gap: 6px
        &-img
          width: 24px
      &-experience
        display: flex
        gap: 6px
        align-items: center
      &-experience ::v-deep b
        position: relative
        z-index: 1
        &::before
          content: ''
          background: var(--purple-10)
          border-radius: 999999px
          width: 24px
          height: 24px
          position: absolute
          left: 50%
          transform: translate(-50%)
          z-index: -1

.specialist-img
  transition: 2s
  &-avatar
    position: relative
  &-flag
    flex: none
    position: absolute
    right: 0px
    bottom: 0px
    object-fit: cover
    height: 24px
    width: 30px
    border-radius: 5px
    z-index: 10
  &-modal
    position: fixed
    z-index: 1000
    &-backdrop
      position: fixed
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.4)
      top: 0
      left: 0
    &-pic
      position: fixed
      max-width: calc( 100vw - 32px )
      height: 343px
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      opacity: 1
      object-fit: cover
      border-radius: 16px

    .modal-in
      animation: modalInCenter .15s ease-in-out

    .modal-out
      animation: modalOutCenter .15s ease-in-out
.metrics
  display: flex
  &-satisfaction
    display: flex
    gap: 4px
    align-items: center

@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%
</style>
