<template>
  <div>
    <template v-if="isDesktop">
      <StickyTabContainer
        :title="title"
        :back="back"
        :action="action"
        :tabs="options"
        :route="$route"
        :loading="loading"
        :message="message"
        :timeout="500"
        @click-tab="handlerSegmentEvents"
      >
        <template v-slot:cover>
          <SpecialistHeader
            :specialist="specialist"
            :is-desktop="isDesktop"
            :content="content"
          />
        </template>

        <template v-slot:column-float>
          <ScheduleExpress ref="schedule" :specialist="specialist" />
        </template>
      </StickyTabContainer>
    </template>

    <StickyTabContainer
      :title="title"
      :back="back"
      :action="action"
      :tabs="options"
      :route="$route"
      :loading="loading"
      :submit="submit"
      :message="message"
      :timeout="500"
      @click-tab="handlerSegmentEvents"
      v-else
    />

    <SpecialistsFavoriteDialog
      v-if="showModal"
      :favorite-id="specialist.specialist_favorite || favoriteId"
      :specialist="specialist"
      @close="closeModal($event)"
    />
  </div>
</template>

<script>
import {
  StickyTabContainer,
  SpecialistProfile,
  SpecialistFavorite,
  UsersAppVisibility,
  ResponsiveMixin
} from '@seliaco/red-panda'
import SpecialistsFavoriteDialog from '@/components/dialogs/SpecialistsFavoriteDialog'
import ScheduleExpress from '@/views/appointments/components/ScheduleExpress.vue'
import SpecialistHeader from '../../components/v2/SpecialistHeader.vue'

import AboutMe from '../../sections/v2/AboutMe.vue'
import Services from '../../sections/v2/Services.vue'
import Reviews from '../../sections/v2/Reviews.vue'
import Style from '../../sections/v2/Style.vue'
import {
  SpecialistProfileEvent,
  ScheduleButton,
  BookingAndAppointmentDetailEvent
} from '@/types/events'

export default {
  name: 'SpecialistProfileV3',
  components: {
    SpecialistHeader,
    StickyTabContainer,
    SpecialistsFavoriteDialog,
    ScheduleExpress
  },
  mixins: [ResponsiveMixin],
  data () {
    const back = {
      icon: 'icon-arrow-full-outline-left',
      callback: () => {
        const query = { ...this.$route.query }
        delete query.back
        if (this.$route.query.back) {
          this.$router.replace({
            path: this.$route.query.back,
            query: {
              ...query,
              directory_origin: 'SpecialistProfileBack'
            }
          })
        } else {
          this.$router.push({ name: 'Home' })
        }
      }
    }

    const submit = {
      text: this.$translations['specialist-page'].button,
      loading: false,
      icon: 'icon-timer-calendar-off',
      iconLeft: true,
      iconSize: 'xl',
      callback: () => this.goSchedule(),
      disabled: false
    }

    return {
      back,
      submit,
      message: null,
      loading: false,
      isFavorite: false,
      showModal: false,
      showLottie: false,
      specialist: null,
      content: null,
      favoriteId: null
    }
  },
  async created () {
    await this.getSpecialistData()
  },
  mounted () {
    setTimeout(() => {
      let is_calendar_visible = '-'

      if (this.isDesktop) {
        is_calendar_visible = Boolean(this.$refs.schedule)
      }

      SpecialistProfileEvent.specialistView({
        user: this.$store.getters['auth/user'],
        origin: this.$route.query.origin,
        back: this.$route.query.back?.split('?')[0],
        specialist: {
          id: this.$route.params.id,
          name: this.specialist.name,
          // is_public: this.specialist.is_public,
          // gender: this.specialist.user.gender,
          country: this.specialist.country,
          speciality: this.specialist.specialist_type
        },
        is_calendar_visible,
        is_desktop: this.isDesktop
      })
    }, 3000)
  },
  destroyed () {
    this.$store.dispatch('specialistProfileService/reset')
  },
  methods: {
    async getSpecialistData () {
      this.loading = true

      const { data, content } = await SpecialistProfile.getById(
        this.$route.params.id
      )

      const specialistData = await SpecialistProfile.getGenInfo(
        this.$route.params.id
      )

      /**
       * Check if message button can be show
       */
      const showMessageButton =
        await UsersAppVisibility.specialistProfileChatButton(
          this.$route.params.id
        )
      if (showMessageButton) {
        this.message = {
          loading: false,
          icon: 'icon-message-circle-off',
          iconLeft: true,
          iconSize: 'xl',
          type: 'outline',
          callback: () => this.goToMessage(data.user_id),
          disabled: false
        }
      }

      // specialists data init
      this.content = content
      this.specialist = {
        id: this.$route.params.id,
        favorite: specialistData.specialist_favorite,
        name: specialistData.about_me.name,
        ...data
      }

      if (
        process.env.VUE_APP_MODE === 'DEV' &&
        data.id === 'a3333547-b11c-4146-aeea-609cf10088ae'
      ) {
        this.specialist = {
          ...this.specialist,
          has_retention_badge: true,
          experience_badge: 50
        }

        this.content = {
          ...this.content,
          retention_badge_text: 'Especialista destacado',
          retention_badge_tooltip: 'Excelente continuidad en sus procesos',
          experience_badge: '<b>+50</b> Citas en Selia'
        }
      }

      this.submit.disabled = !this.specialist.is_public

      // handler heart
      if (specialistData.specialist_favorite) {
        this.isFavorite = true
      }

      this.loading = false
    },
    goToMessage (userSpecialistId) {
      this.$router.push({
        name: 'Messages',
        query: {
          members: JSON.stringify([
            this.$store.getters['auth/user'].id,
            userSpecialistId
          ]),
          back: this.$route.fullPath
        }
      })
    },
    closeModal (event) {
      if (event) {
        this.isFavorite = false
      }
      this.showModal = false
    },
    addSpecialistFavorite () {
      this.showLottie = true
      SpecialistFavorite.create({ specialist: this.$route.params.id })
        .then((res) => {
          this.$toast({
            text: this.$translations['my-specialists']['toast-create'],
            severity: 'success'
          })
          this.isFavorite = true
          this.favoriteId = res.id
          SpecialistProfileEvent.addFavorite({
            specialistId: this.specialist.id,
            specialistName: this.specialist.name
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() =>
          setTimeout(() => {
            this.showLottie = false
          }, 2000)
        )
    },
    goSchedule () {
      const body = {
        user: this.$store.getters['auth/user'],
        specialist_id: this.specialist.id,
        specialist_name: this.specialist.name,
        is_public: this.specialist.is_public,
        origin: 'Specialist Profile Mobile'
      }

      ScheduleButton.onClick(body)

      this.$router
        .push({
          name: 'Schedule',
          query: {
            specialistId: this.$route.params.id,
            back: this.$route.fullPath,
            origin: 'specialist-profile'
          }
        })
        .then(() =>
          BookingAndAppointmentDetailEvent.loadViewspecialist({
            origin: this.$route.query.back,
            user: this.$store.getters['auth/user']
          })
        )
    },
    handlerSegmentEvents (anchor) {
      SpecialistProfileEvent.selectTab({ anchor })
    }
  },
  computed: {
    action () {
      return {
        callback: () => {
          if (this.isFavorite) {
            this.showModal = true
          } else {
            this.addSpecialistFavorite()
          }
        },
        icon: this.isFavorite ? 'icon-heart-on text-red' : 'icon-heart-off',
        lottie:
          'https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/lottie/lottie-sparkles.json',
        showLottie: this.showLottie
      }
    },
    title () {
      return this.specialist?.name || ''
    },

    isDesktop: {
      get () {
        return this.windowWidth >= 980
      },
      set (windowWidth) {
        return windowWidth >= 980
      }
    },
    options () {
      if (!this.specialist && !this.content) {
        return []
      }

      const propsCommon = {
        specialist: this.specialist,
        content: this.content,
        isDesktop: this.isDesktop
      }

      let array = [
        {
          anchor: 'about-me',
          name: this.$translations['specialist-page'].about,
          component: AboutMe,
          props: {
            ...propsCommon,
            multimedia: this.specialist?.multimedia
          }
        },
        {
          component: Style,
          name: this.$translations['specialist-page'].style,
          anchor: 'style',
          props: {
            ...propsCommon,
            experiences: this.specialist.experiences,
            population: this.specialist.populations
          }
        },
        {
          component: Services,
          name: this.$translations['specialist-page'].price.tab,
          anchor: 'services',
          props: {
            ...propsCommon
          }
        }
      ]

      if (this.specialist?.reviews?.length) {
        const reviewsSection = {
          component: Reviews,
          name: this.$translations['specialist-page'].reviews.title,
          anchor: 'reviews',
          props: {
            ...propsCommon,
            showButton: this.specialist.reviews.length > 5,
            reviews: this.specialist.reviews
          }
        }

        array = [...array, reviewsSection]
      }

      return array
    }
  }
}
</script>
